export interface AppInfo {
  readonly commitHash: string;
  readonly branch: string;
  readonly pipelineId: string;
}
// Gets stamped by CI
export const appInfo: AppInfo = {
  branch: "master",
  pipelineId: "119188",
  commitHash: "COMMIT_REF_SHORT",
};
