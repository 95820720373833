import * as ReactDOM from "react-dom";
import * as React from "react";
import { EffectManager, Program } from "@typescript-tea/core";
import { appInfo } from "@project-name/shared";
import { initSentry } from "./sentry";
import { clientConfig } from "./client-config";
// import * as Root from "./root";
// import * as NavigationEffectManager from "./infrastructure/effect-managers/navigation";
// import * as OidcEffectManager from "./infrastructure/effect-managers/oidc-client";
// import * as HttpEffectManager from "./infrastructure/effect-managers/http-fetch";
// import * as LocalStorageEffectManager from "./infrastructure/effect-managers/local-storage";

initSentry(clientConfig.dsn, appInfo.pipelineId, clientConfig.env);

// Run the program
const rootElement = document.getElementById("root");
const render = (view: JSX.Element): void => ReactDOM.render(view, rootElement);

const effectManagers: readonly EffectManager[] = [
  // NavigationEffectManager.createEffectManager<Root.Action>(Root.Action.UrlChanged, Root.Action.UrlRequested),
  // OidcEffectManager.createOidcEffectManager(),
  // HttpEffectManager.createEffectManager(),
  // LocalStorageEffectManager.createEffectManager(),
];

// const rootProgram: Program<NavigationEffectManager.Url, Root.State, Root.Action, JSX.Element> = {
//     init: Root.init,
//     update: Root.update,
//     view: Root.view,
//     subscriptions: Root.subscriptions,
// };

// Program.run(rootProgram, NavigationEffectManager.getCurrentUrl(), render, effectManagers);
/* eslint-disable @typescript-eslint/no-explicit-any */
const rootProgram: Program<() => {}, {}, () => {}, JSX.Element> = {
  init: () => [{}],
  update: () => [{}],
  view: () => {
    return (
      <div>
        <div>Hello World</div>
        <div>
          Client env 6
          <br />
          {JSON.stringify(clientConfig)}
        </div>

        <button onClick={() => (window as any).breakWorld()}>Break</button>
      </div>
    );
  },
  // subscriptions: Root.subscriptions,
};

Program.run(rootProgram, () => [], render, effectManagers);
